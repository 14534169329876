<template>
  <div>
    <div class="col-md-12">
      <div class="card card-info card-outline">
        <div class="row justify-content-md-center">
          <div class="col-md-12">
            <div class="card-header">
              <h3 class="card-title text-black text-bold">
                <i class="fas fa-file-alt"></i>
                Detalle Solicitud
              </h3>
            </div>

            <div class="col-md-12">
              <div class="row">
                <div class="col-sm-3">
                  <div class="description-block">
                    <p class="description-header">
                      <i class="fas fa-box"></i>{{ " " + servicio.id }}
                    </p>
                    <span class="description-text text-secondary"
                      >Servicio:</span
                    >
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="description-block">
                    <p class="description-header">
                      <i class="fas fa-shipping-fast"></i
                      >{{ " " + servicio.nTipoServicio }}
                    </p>
                    <span class="description-text text-secondary"
                      >Tipo Servicio:</span
                    >
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="description-block">
                    <p class="description-header">
                      <i class="fas fa-cube"></i
                      >{{
                        " " + servicio.bloque != null &&
                        servicio.bloque != undefined
                          ? servicio.bloque.nombre
                          : ""
                      }}
                    </p>
                    <span class="description-text text-secondary">Bloque:</span>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="description-block">
                    <p class="description-header">
                      <i class="fas fa-map-marked-alt"></i
                      >{{
                        " " + servicio.sitio != null &&
                        servicio.sitio != undefined
                          ? servicio.sitio.nombre
                          : ""
                      }}
                    </p>
                    <span class="description-text text-secondary">Sitio:</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-2"></div>
                <div class="col-sm-2">
                  <div
                    class="description-block"
                    v-if="
                      servicio.empresa != null && servicio.empresa != undefined
                    "
                  >
                    <p
                      v-if="servicio.empresa.razon_social.length > 20"
                      class="description-header"
                      style="font-size: 12px;"
                    >
                      <i class="fas fa-university" style="font-size: 18px;"></i>
                      {{ servicio.empresa.razon_social }}
                    </p>
                    <p v-else class="description-header">
                      <i class="fas fa-university"></i>
                      {{ servicio.empresa.razon_social }}
                    </p>
                    <span class="description-text text-secondary"
                      >Empresa:</span
                    >
                  </div>
                </div>

                <div class="col-sm-1"></div>
                <div class="col-sm-2">
                  <div class="description-block">
                    <p
                      v-if="servicio.tipo_servicio == 1"
                      class="description-header"
                    >
                      <i class="fas fa-truck-loading"></i
                      >{{
                        " " + servicio.postulacion &&
                        servicio.postulacion.det_solicitud_interna != null &&
                        servicio.postulacion.det_solicitud_interna != undefined
                          ? servicio.postulacion.det_solicitud_interna.nTipoVh
                          : ""
                      }}
                    </p>
                    <p v-else class="description-header">
                      <i class="fas fa-truck-loading"></i
                      >{{
                        " " + servicio.postulacion &&
                        servicio.postulacion.det_solicitud_interna != null &&
                        servicio.postulacion.det_solicitud_interna != undefined
                          ? servicio.postulacion.det_solicitud_interna.nTipoEqui
                          : ""
                      }}
                    </p>
                    <span class="description-text text-secondary"
                      >Tipo de Vehiculo:</span
                    >
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="description-block">
                    <p class="description-header">
                      <i class="fas fa-clock"></i
                      >{{
                        " " + servicio.postulacion &&
                        servicio.postulacion.det_solicitud_interna != null &&
                        servicio.postulacion.det_solicitud_interna != undefined
                          ? " " +
                            servicio.postulacion.det_solicitud_interna.nTurno
                          : ""
                      }}
                    </p>
                    <span class="description-text text-secondary"
                      >Horas Turno:</span
                    >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <div class="description-block">
                    <p class="description-header">
                      <i class="fas fa-truck-moving"></i
                      >{{
                        " " + servicio.equipo != null &&
                        servicio.equipo != undefined
                          ? servicio.equipo.placa
                          : ""
                      }}
                    </p>
                    <span class="description-text text-secondary"
                      >{{
                        servicio.tipo_servicio == 1 ? "Vehiculo" : "Equipo"
                      }}:</span
                    >
                  </div>
                </div>
                <div class="col-sm-3" v-if="servicio.remolque != null">
                  <div class="description-block">
                    <p class="description-header">
                      <i class="fas fa-truck-loading"></i
                      >{{ " " + servicio.remolque.placa }}
                    </p>
                    <span
                      class="description-text text-secondary"
                      v-if="servicio.remolque != null"
                      >Trailer:</span
                    >
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="description-block">
                    <p class="description-header">
                      <i class="fas fa-id-badge"></i
                      >{{
                        " " + servicio.persona != null &&
                        servicio.persona != undefined
                          ? servicio.persona.nombres +
                            " " +
                            servicio.persona.apellidos
                          : " "
                      }}
                    </p>
                    <span class="description-text text-secondary"
                      >{{
                        servicio.tipo_servicio == 1 ? "Conductor" : "Operario"
                      }}:</span
                    >
                  </div>
                </div>
                <div class="col-sm-3" v-if="servicio.persona_dos">
                  <div class="description-block">
                    <p class="description-header">
                      <i class="fas fa-id-badge"></i
                      >{{
                        " " +
                          servicio.persona_dos.nombres +
                          " " +
                          servicio.persona_dos.apellidos
                      }}
                    </p>
                    <span class="description-text text-secondary"
                      >{{
                        servicio.tipo_servicio == 1
                          ? "Segundo Conductor"
                          : "Segundo Operario"
                      }}:</span
                    >
                  </div>
                </div>
                <div class="col-sm-3" v-if="servicio.aparejador_id">
                  <div class="description-block">
                    <p class="description-header">
                      <i class="fas fa-user-tie"></i
                      >{{
                        " " +
                          servicio.aparejador.nombres +
                          " " +
                          servicio.aparejador.apellidos
                      }}
                    </p>
                    <span
                      class="description-text text-secondary"
                      v-if="servicio.aparejador_id"
                      >Aparejador:</span
                    >
                  </div>
                </div>

                <div
                  class="col-sm-3"
                  v-if="
                    servicio.postulacion &&
                      servicio.postulacion.det_solicitud_interna
                  "
                >
                  <div class="description-block">
                    <p class="description-header">
                      <i class="fas fa-comment-dots"></i
                      >{{
                        " " +
                          servicio.postulacion.det_solicitud_interna
                            .observaciones
                      }}
                    </p>
                    <span class="description-text text-secondary"
                      >Observación:</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12">
      <div class="card card-lightblue card-outline collapsed-card">
        <div class="card-header pt-2 pb-2">
          <h3 class="card-title">
            <i class="fa fa-truck text-dark"></i> Reporte Trabajo:
            {{
              " " + servicio.persona != null && servicio.persona != undefined
                ? servicio.persona.nombres + " " + servicio.persona.apellidos
                : ""
            }}
          </h3>
          <div class="card-tools">
            <button
              type="button"
              data-card-widget="collapse"
              data-toggle="tooltip"
              title="Collapse"
              class="btn btn-tool"
            >
              <i class="fas fa-plus"></i>
            </button>
          </div>
        </div>
        <div class="card-body">
          <div class="card-title text-info lg" v-if="csDetalles.length > 0">
            <i class="fa fa-user-clock"></i> Hora Normal
          </div>
          <table
            class="table table-bordered table-striped table-hover table-sm"
          >
            <thead class="bg-dark">
              <tr class="text-center">
                <th class="col-2">Fecha Inicio</th>
                <th class="col-2">Hora Inicio</th>
                <th class="col-2">Fecha Fin</th>
                <th class="col-2">Hora Fin</th>
                <th class="col-2">Duración <small>(horas)</small></th>
                <th class="col-1">Tipo Tiempo</th>
                <th>Observaciones</th>
              </tr>
            </thead>
            <tbody id="tbody">
              <tr
                v-for="detalle_servicio in csDetalles"
                :key="detalle_servicio.id"
              >
                <td class="text-center">
                  {{ detalle_servicio.fecha_ini }}
                </td>
                <td class="text-center">
                  {{ detalle_servicio.hora_ini }}
                </td>
                <td class="text-center">
                  {{ detalle_servicio.fecha_fin }}
                </td>
                <td class="text-center">
                  {{ detalle_servicio.hora_fin }}
                </td>
                <td class="text-center text-nowrap">
                  <div v-if="detalle_servicio.tiempo">
                    <div v-if="detalle_servicio.tiempo.cant_horas_mostrar">
                      {{ detalle_servicio.tiempo.cant_horas_mostrar }}
                    </div>
                  </div>
                  <div v-else>Sin establecer</div>
                </td>
                <td class="text-center">
                  <span
                    class="badge"
                    :class="
                      detalle_servicio.tipo_tiempo == 1
                        ? 'badge-success'
                        : detalle_servicio.tipo_tiempo == 2
                        ? 'badge-warning'
                        : detalle_servicio.tipo_tiempo == 3
                        ? 'badge-danger'
                        : 'bg-secundary'
                    "
                  >
                    {{ detalle_servicio.nTipoTiempo }}
                  </span>
                </td>
                <td>{{ detalle_servicio.observaciones }}</td>
              </tr>
            </tbody>
            <tbody v-if="datos_barra">
              <tr class="bg-light">
                <td colspan="7">
                  <div class="progress">
                    <div
                      role="progressbar"
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      class="progress-bar progress-bar-striped bg-success progress-bar-animated"
                      :style="
                        `width: ${
                          datos_barra.operativo.porcentaje != 'NaN'
                            ? datos_barra.operativo.porcentaje
                            : 0
                        }%`
                      "
                    >
                      <b>
                        {{
                          datos_barra.operativo.tiempo.years
                            ? `Años: ${datos_barra.operativo.tiempo.years}`
                            : ""
                        }}
                        {{
                          datos_barra.operativo.tiempo.meses
                            ? `Meses: ${datos_barra.operativo.tiempo.meses}`
                            : ""
                        }}
                        {{
                          datos_barra.operativo.tiempo.dias
                            ? `Días: ${datos_barra.operativo.tiempo.dias}`
                            : ""
                        }}
                        {{
                          datos_barra.operativo.tiempo.horas
                            ? `Horas: ${datos_barra.operativo.tiempo.horas}`
                            : ""
                        }}
                        {{
                          datos_barra.operativo.tiempo.minutos
                            ? `Minutos: ${datos_barra.operativo.tiempo.minutos}`
                            : ""
                        }}
                        ({{
                          datos_barra.operativo.porcentaje != "NaN"
                            ? datos_barra.operativo.porcentaje
                            : 0
                        }}%)
                      </b>
                    </div>
                    <div
                      role="progressbar"
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      class="progress-bar progress-bar-striped bg-warning progress-bar-animated"
                      :style="
                        `width: ${
                          datos_barra.disponible.porcentaje != 'NaN'
                            ? datos_barra.disponible.porcentaje
                            : 0
                        }%`
                      "
                    >
                      <b>
                        {{
                          datos_barra.disponible.tiempo.years
                            ? `Años: ${datos_barra.disponible.tiempo.years}`
                            : ""
                        }}
                        {{
                          datos_barra.disponible.tiempo.meses
                            ? `Meses: ${datos_barra.disponible.tiempo.meses}`
                            : ""
                        }}
                        {{
                          datos_barra.disponible.tiempo.dias
                            ? `Días: ${datos_barra.disponible.tiempo.dias}`
                            : ""
                        }}
                        {{
                          datos_barra.disponible.tiempo.horas
                            ? `Horas: ${datos_barra.disponible.tiempo.horas}`
                            : ""
                        }}
                        {{
                          datos_barra.disponible.tiempo.minutos
                            ? `Minutos: ${datos_barra.disponible.tiempo.minutos}`
                            : ""
                        }}
                        ({{
                          datos_barra.disponible.porcentaje != "NaN"
                            ? datos_barra.disponible.porcentaje
                            : 0
                        }}%)
                      </b>
                    </div>
                    <div
                      role="progressbar"
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      class="progress-bar progress-bar-striped bg-danger progress-bar-animated"
                      :style="
                        `width: ${
                          datos_barra.indisponible.porcentaje != 'NaN'
                            ? datos_barra.indisponible.porcentaje
                            : 0
                        }%`
                      "
                    >
                      <b>
                        {{
                          datos_barra.indisponible.tiempo.years
                            ? `Años: ${datos_barra.indisponible.tiempo.years}`
                            : ""
                        }}
                        {{
                          datos_barra.indisponible.tiempo.meses
                            ? `Meses: ${datos_barra.indisponible.tiempo.meses}`
                            : ""
                        }}
                        {{
                          datos_barra.indisponible.tiempo.dias
                            ? `Días: ${datos_barra.indisponible.tiempo.dias}`
                            : ""
                        }}
                        {{
                          datos_barra.indisponible.tiempo.horas
                            ? `Horas: ${datos_barra.indisponible.tiempo.horas}`
                            : ""
                        }}
                        {{
                          datos_barra.indisponible.tiempo.minutos
                            ? `Minutos: ${datos_barra.indisponible.tiempo.minutos}`
                            : ""
                        }}
                        ({{
                          datos_barra.indisponible.porcentaje != "NaN"
                            ? datos_barra.indisponible.porcentaje
                            : 0
                        }}%)
                      </b>
                    </div>
                    <div
                      role="progressbar"
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      class="progress-bar progress-bar-striped bg-primary progress-bar-animated"
                      :style="
                        `width: ${
                          datos_barra.movilizacion.porcentaje != 'NaN'
                            ? datos_barra.movilizacion.porcentaje
                            : 0
                        }%`
                      "
                    >
                      <b>
                        {{
                          datos_barra.movilizacion.tiempo.years
                            ? `Años: ${datos_barra.movilizacion.tiempo.years}`
                            : ""
                        }}
                        {{
                          datos_barra.movilizacion.tiempo.meses
                            ? `Meses: ${datos_barra.movilizacion.tiempo.meses}`
                            : ""
                        }}
                        {{
                          datos_barra.movilizacion.tiempo.dias
                            ? `Días: ${datos_barra.movilizacion.tiempo.dias}`
                            : ""
                        }}
                        {{
                          datos_barra.movilizacion.tiempo.horas
                            ? `Horas: ${datos_barra.movilizacion.tiempo.horas}`
                            : ""
                        }}
                        {{
                          datos_barra.movilizacion.tiempo.minutos
                            ? `Minutos: ${datos_barra.movilizacion.tiempo.minutos}`
                            : ""
                        }}
                        ({{
                          datos_barra.movilizacion.porcentaje != "NaN"
                            ? datos_barra.movilizacion.porcentaje
                            : 0
                        }}%)
                      </b>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <br />
          <div
            class="card-title text-info lg"
            v-if="csDetallesExtras.length > 0"
          >
            <i class="fa fa-user-clock"></i> Hora Extra
          </div>

          <table
            class="table table-bordered table-striped table-hover table-sm"
            v-if="csDetallesExtras.length > 0"
          >
            <thead class="bg-dark">
              <tr class="text-center">
                <th class="col-2">Fecha Inicio</th>
                <th class="col-2">Hora Inicio</th>
                <th class="col-2">Fecha Fin</th>
                <th class="col-2">Hora Fin</th>
                <th class="col-2">Duración <small>(horas)</small></th>
                <th class="col-1">Tipo Tiempo</th>
                <th>Observaciones</th>
              </tr>
            </thead>
            <tbody id="tbody">
              <tr
                v-for="detalle_servicioextra in csDetallesExtras"
                :key="detalle_servicioextra.id"
              >
                <td class="text-center">
                  {{ detalle_servicioextra.fecha_ini }}
                </td>
                <td class="text-center">
                  {{ detalle_servicioextra.hora_ini }}
                </td>
                <td class="text-center">
                  {{ detalle_servicioextra.fecha_fin }}
                </td>
                <td class="text-center">
                  {{ detalle_servicioextra.hora_fin }}
                </td>
                <td class="text-center text-nowrap">
                  <div v-if="detalle_servicioextra.tiempo">
                    <div v-if="detalle_servicioextra.tiempo.cant_horas_mostrar">
                      {{ detalle_servicioextra.tiempo.cant_horas_mostrar }}
                    </div>
                  </div>
                  <div v-else>Sin establecer</div>
                </td>
                <td class="text-center">
                  <span
                    class="badge"
                    :class="
                      detalle_servicioextra.tipo_tiempo == 1
                        ? 'badge-success'
                        : detalle_servicioextra.tipo_tiempo == 2
                        ? 'badge-warning'
                        : detalle_servicioextra.tipo_tiempo == 3
                        ? 'badge-danger'
                        : 'bg-secundary'
                    "
                  >
                    {{ detalle_servicioextra.nTipoTiempo }}
                  </span>
                </td>
                <td>{{ detalle_servicioextra.observaciones }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="col-md-12" v-if="csDetalles2.length > 0">
      <div class="card card-lightblue card-outline collapsed-card">
        <div class="card-header pt-2 pb-2">
          <h3 class="card-title">
            <i class="fa fa-truck text-dark"></i> Reporte Trabajo Segundo
            Conductor:
            {{
              " " + servicio.persona_dos != null &&
              servicio.persona_dos != undefined
                ? servicio.persona_dos.nombres +
                  " " +
                  servicio.persona_dos.apellidos
                : " "
            }}
          </h3>
          <div class="card-tools">
            <button
              type="button"
              data-card-widget="collapse"
              data-toggle="tooltip"
              title="Collapse"
              class="btn btn-tool"
            >
              <i class="fas fa-plus"></i>
            </button>
          </div>
        </div>
        <div class="card-body">
          <div
            class="card-title text-info lg"
            v-if="csDetallesExtras2.length > 0"
          >
            <i class="fa fa-user-clock"></i> Hora Normal
          </div>
          <table
            class="table table-bordered table-striped table-hover table-sm"
          >
            <thead class="bg-dark">
              <tr class="text-center">
                <th class="col-2">Fecha Inicio</th>
                <th class="col-2">Hora Inicio</th>
                <th class="col-2">Fecha Fin</th>
                <th class="col-2">Hora Fin</th>
                <th class="col-2">Duración <small>(horas)</small></th>
                <th class="col-1">Tipo Tiempo</th>
                <th>Observaciones</th>
              </tr>
            </thead>
            <tbody id="tbody">
              <tr
                v-for="detalle_servicio in csDetalles2"
                :key="detalle_servicio.id"
              >
                <td class="text-center">
                  {{ detalle_servicio.fecha_ini }}
                </td>
                <td class="text-center">
                  {{ detalle_servicio.hora_ini }}
                </td>
                <td class="text-center">
                  {{ detalle_servicio.fecha_fin }}
                </td>
                <td class="text-center">
                  {{ detalle_servicio.hora_fin }}
                </td>
                <td class="text-center text-nowrap">
                  <div v-if="detalle_servicio.tiempo">
                    <div v-if="detalle_servicio.tiempo.cant_horas_mostrar">
                      {{ detalle_servicio.tiempo.cant_horas_mostrar }}
                    </div>
                  </div>
                  <div v-else>Sin establecer</div>
                </td>
                <td class="text-center">
                  <span
                    class="badge"
                    :class="
                      detalle_servicio.tipo_tiempo == 1
                        ? 'badge-success'
                        : detalle_servicio.tipo_tiempo == 2
                        ? 'badge-warning'
                        : detalle_servicio.tipo_tiempo == 3
                        ? 'badge-danger'
                        : 'bg-secundary'
                    "
                  >
                    {{ detalle_servicio.nTipoTiempo }}
                  </span>
                </td>
                <td>{{ detalle_servicio.observaciones }}</td>
              </tr>
            </tbody>
            <tbody v-if="datos_barra2">
              <tr class="bg-light">
                <td colspan="7">
                  <div class="progress">
                    <div
                      role="progressbar"
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      class="progress-bar progress-bar-striped bg-success progress-bar-animated"
                      :style="
                        `width: ${
                          datos_barra2.operativo.porcentaje != 'NaN'
                            ? datos_barra2.operativo.porcentaje
                            : 0
                        }%`
                      "
                    >
                      <b>
                        {{
                          datos_barra2.operativo.tiempo.years
                            ? `Años: ${datos_barra2.operativo.tiempo.years}`
                            : ""
                        }}
                        {{
                          datos_barra2.operativo.tiempo.meses
                            ? `Meses: ${datos_barra2.operativo.tiempo.meses}`
                            : ""
                        }}
                        {{
                          datos_barra2.operativo.tiempo.dias
                            ? `Días: ${datos_barra2.operativo.tiempo.dias}`
                            : ""
                        }}
                        {{
                          datos_barra2.operativo.tiempo.horas
                            ? `Horas: ${datos_barra2.operativo.tiempo.horas}`
                            : ""
                        }}
                        {{
                          datos_barra2.operativo.tiempo.minutos
                            ? `Minutos: ${datos_barra2.operativo.tiempo.minutos}`
                            : ""
                        }}
                        ({{
                          datos_barra2.operativo.porcentaje != "NaN"
                            ? datos_barra2.operativo.porcentaje
                            : 0
                        }}%)
                      </b>
                    </div>
                    <div
                      role="progressbar"
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      class="progress-bar progress-bar-striped bg-warning progress-bar-animated"
                      :style="
                        `width: ${
                          datos_barra2.disponible.porcentaje != 'NaN'
                            ? datos_barra2.disponible.porcentaje
                            : 0
                        }%`
                      "
                    >
                      <b>
                        {{
                          datos_barra2.disponible.tiempo.years
                            ? `Años: ${datos_barra2.disponible.tiempo.years}`
                            : ""
                        }}
                        {{
                          datos_barra2.disponible.tiempo.meses
                            ? `Meses: ${datos_barra2.disponible.tiempo.meses}`
                            : ""
                        }}
                        {{
                          datos_barra2.disponible.tiempo.dias
                            ? `Días: ${datos_barra2.disponible.tiempo.dias}`
                            : ""
                        }}
                        {{
                          datos_barra2.disponible.tiempo.horas
                            ? `Horas: ${datos_barra2.disponible.tiempo.horas}`
                            : ""
                        }}
                        {{
                          datos_barra2.disponible.tiempo.minutos
                            ? `Minutos: ${datos_barra2.disponible.tiempo.minutos}`
                            : ""
                        }}
                        ({{
                          datos_barra2.disponible.porcentaje != "NaN"
                            ? datos_barra2.disponible.porcentaje
                            : 0
                        }}%)
                      </b>
                    </div>
                    <div
                      role="progressbar"
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      class="progress-bar progress-bar-striped bg-danger progress-bar-animated"
                      :style="
                        `width: ${
                          datos_barra2.indisponible.porcentaje != 'NaN'
                            ? datos_barra2.indisponible.porcentaje
                            : 0
                        }%`
                      "
                    >
                      <b>
                        {{
                          datos_barra2.indisponible.tiempo.years
                            ? `Años: ${datos_barra2.indisponible.tiempo.years}`
                            : ""
                        }}
                        {{
                          datos_barra2.indisponible.tiempo.meses
                            ? `Meses: ${datos_barra2.indisponible.tiempo.meses}`
                            : ""
                        }}
                        {{
                          datos_barra2.indisponible.tiempo.dias
                            ? `Días: ${datos_barra2.indisponible.tiempo.dias}`
                            : ""
                        }}
                        {{
                          datos_barra2.indisponible.tiempo.horas
                            ? `Horas: ${datos_barra2.indisponible.tiempo.horas}`
                            : ""
                        }}
                        {{
                          datos_barra2.indisponible.tiempo.minutos
                            ? `Minutos: ${datos_barra2.indisponible.tiempo.minutos}`
                            : ""
                        }}
                        ({{
                          datos_barra2.indisponible.porcentaje != "NaN"
                            ? datos_barra2.indisponible.porcentaje
                            : 0
                        }}%)
                      </b>
                    </div>
                    <div
                      role="progressbar"
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      class="progress-bar progress-bar-striped bg-primary progress-bar-animated"
                      :style="
                        `width: ${
                          datos_barra2.movilizacion.porcentaje != 'NaN'
                            ? datos_barra2.movilizacion.porcentaje
                            : 0
                        }%`
                      "
                    >
                      <b>
                        {{
                          datos_barra2.movilizacion.tiempo.years
                            ? `Años: ${datos_barra2.movilizacion.tiempo.years}`
                            : ""
                        }}
                        {{
                          datos_barra2.movilizacion.tiempo.meses
                            ? `Meses: ${datos_barra2.movilizacion.tiempo.meses}`
                            : ""
                        }}
                        {{
                          datos_barra2.movilizacion.tiempo.dias
                            ? `Días: ${datos_barra2.movilizacion.tiempo.dias}`
                            : ""
                        }}
                        {{
                          datos_barra2.movilizacion.tiempo.horas
                            ? `Horas: ${datos_barra2.movilizacion.tiempo.horas}`
                            : ""
                        }}
                        {{
                          datos_barra2.movilizacion.tiempo.minutos
                            ? `Minutos: ${datos_barra2.movilizacion.tiempo.minutos}`
                            : ""
                        }}
                        ({{
                          datos_barra2.movilizacion.porcentaje != "NaN"
                            ? datos_barra2.movilizacion.porcentaje
                            : 0
                        }}%)
                      </b>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <br />

          <div
            class="card-title text-info lg"
            v-if="csDetallesExtras2.length > 0"
          >
            <i class="fa fa-user-clock"></i> Hora Extra
          </div>

          <table
            class="table table-bordered table-striped table-hover table-sm"
            v-if="csDetallesExtras2.length > 0"
          >
            <thead class="bg-dark">
              <tr class="text-center">
                <th class="col-2">Fecha Inicio</th>
                <th class="col-2">Hora Inicio</th>
                <th class="col-2">Fecha Fin</th>
                <th class="col-2">Hora Fin</th>
                <th class="col-2">Duración <small>(horas)</small></th>
                <th class="col-1">Tipo Tiempo</th>
                <th>Observaciones</th>
              </tr>
            </thead>
            <tbody id="tbody">
              <tr
                v-for="detalle_servicioextra in csDetallesExtras2"
                :key="detalle_servicioextra.id"
              >
                <td class="text-center">
                  {{ detalle_servicioextra.fecha_ini }}
                </td>
                <td class="text-center">
                  {{ detalle_servicioextra.hora_ini }}
                </td>
                <td class="text-center">
                  {{ detalle_servicioextra.fecha_fin }}
                </td>
                <td class="text-center">
                  {{ detalle_servicioextra.hora_fin }}
                </td>
                <td class="text-center text-nowrap">
                  <div v-if="detalle_servicioextra.tiempo">
                    <div v-if="detalle_servicioextra.tiempo.cant_horas_mostrar">
                      {{ detalle_servicioextra.tiempo.cant_horas_mostrar }}
                    </div>
                  </div>
                  <div v-else>Sin establecer</div>
                </td>
                <td class="text-center">
                  <span
                    class="badge"
                    :class="
                      detalle_servicioextra.tipo_tiempo == 1
                        ? 'badge-success'
                        : detalle_servicioextra.tipo_tiempo == 2
                        ? 'badge-warning'
                        : detalle_servicioextra.tipo_tiempo == 3
                        ? 'badge-danger'
                        : 'bg-secundary'
                    "
                  >
                    {{ detalle_servicioextra.nTipoTiempo }}
                  </span>
                </td>
                <td>{{ detalle_servicioextra.observaciones }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="modal-footer justify-content-between">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div> -->
</template>

<script>
import moment from "moment";
import { required, sameAs } from "vuelidate/lib/validators";

export default {
  name: "CsConsultaDetalleCond",
  data() {
    return {
      datos_barra: null,
      datos_barra2: null,
      csDetalles: [],
      csDetalles2: [],
      csDetallesExtras: [],
      servicio: {
        postulacion: {},
      },
      id: null,
      modalData: [],
      form: {},
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },

  validations() {
    if (!this.$store.getters.getAuthAzure) {
      return {
        form: {
          pass: {
            required,
          },
          re_pass: {
            required,
            sameAsPassword: sameAs("pass"),
          },
          observacion: {
            required,
          },
        },
      };
    } else {
      return {
        form: {
          observacion: {
            required,
          },
        },
      };
    }
  },

  methods: {
    async getIndex(servicio) {
      this.limpiarModal();
      this.form = {};
      this.servicio = servicio;
      this.csDetalles = servicio.detalle_servicios.filter(
        (det) => det.tipo_hora == 1 && det.conductor_id === servicio.persona_id
      );
      this.csDetallesExtras = servicio.detalle_servicios.filter(
        (det) => det.tipo_hora == 2 && det.conductor_id == servicio.persona_id
      );
      this.csDetalles2 = servicio.detalle_servicios.filter(
        (det) => det.tipo_hora == 1 && det.conductor_id == servicio.persona_2_id
      );
      this.csDetallesExtras2 = servicio.detalle_servicios.filter(
        (det) => det.tipo_hora == 2 && det.conductor_id == servicio.persona_2_id
      );

      await this.getDatosBarra();
    },

    getTiempo(hora_ini, hora_fin) {
      if (hora_ini && hora_fin) {
        let inicio = moment(hora_ini);
        let fin = moment(hora_fin);
        let diferencia = fin.diff(inicio);

        // Se calcula la cantidad en horas según requerimiento de Frontera
        const cant_horas = diferencia / 1000 / 60 / 60;
        const cant_minutos = (cant_horas - parseInt(cant_horas)) * 10;

        return {
          years: moment.duration(diferencia).years(),
          meses: moment.duration(diferencia).months(),
          dias: moment.duration(diferencia).days(),
          horas: moment.duration(diferencia).hours(),
          minutos: moment.duration(diferencia).minutes(),
          cant_horas_mostrar:
            cant_minutos < 5 ? parseInt(cant_horas) : parseInt(cant_horas + 1),
        };
      }
      return null;
    },

    async getDatosBarra() {
      this.datos_barra = {
        operativo: {
          cant: 0,
          tiempo: {},
          porcentaje: 0,
        },
        disponible: {
          cant: 0,
          tiempo: {},
          porcentaje: 0,
        },
        indisponible: {
          cant: 0,
          tiempo: {},
          porcentaje: 0,
        },
        movilizacion: {
          cant: 0,
          tiempo: {},
          porcentaje: 0,
        },
      };

      this.csDetalles.forEach((det) => {
        // Se calcula el tiempo del reporte
        const inicio_reporte = new Date(`${det.hora_ini}`);
        const fin_reporte = new Date(`${det.hora_fin}`);
        const tiempo_reporte = fin_reporte.getTime() - inicio_reporte.getTime();

        switch (det.tipo_tiempo) {
          case 1:
            this.datos_barra.operativo.cant += tiempo_reporte;
            break;
          case 2:
            this.datos_barra.disponible.cant += tiempo_reporte;
            break;
          case 3:
            this.datos_barra.indisponible.cant += tiempo_reporte;
            break;
          case 4:
            this.datos_barra.movilizacion.cant += tiempo_reporte;
            break;
          default:
            break;
        }
      });

      this.csDetalles.forEach((det) => {
        const inicio_f = det.hora_ini.split(" ");
        const fin_f = det.hora_fin.split(" ");
        det.fecha_ini = inicio_f[0];
        det.hora_ini = inicio_f[1];
        det.fecha_fin = fin_f[0];
        det.hora_fin = fin_f[1];
        det.tiempo = this.getTiempo(
          `${det.fecha_ini} ${det.hora_ini}`,
          `${det.fecha_fin} ${det.hora_fin}`
        );
      });

      this.csDetallesExtras.forEach((det) => {
        const inicio_f = det.hora_ini.split(" ");
        const fin_f = det.hora_fin.split(" ");
        det.fecha_ini = inicio_f[0];
        det.hora_ini = inicio_f[1];
        det.fecha_fin = fin_f[0];
        det.hora_fin = fin_f[1];
        det.tiempo = this.getTiempo(
          `${det.fecha_ini} ${det.hora_ini}`,
          `${det.fecha_fin} ${det.hora_fin}`
        );
      });

      this.csDetallesExtras2.forEach((det) => {
        const inicio_f = det.hora_ini.split(" ");
        const fin_f = det.hora_fin.split(" ");
        det.fecha_ini = inicio_f[0];
        det.hora_ini = inicio_f[1];
        det.fecha_fin = fin_f[0];
        det.hora_fin = fin_f[1];
        det.tiempo = this.getTiempo(
          `${det.fecha_ini} ${det.hora_ini}`,
          `${det.fecha_fin} ${det.hora_fin}`
        );
      });

      let fechaIni = this.servicio.fecha_ini.split(" ");
      const inicio_servicio = new Date(`${this.servicio.fecha_ini}`);
      const fin_servicio = new Date(`${fechaIni[0]} ${"18:00"}`);
      const tiempo_servicio =
        fin_servicio.getTime() - inicio_servicio.getTime();

      this.datos_barra.operativo.porcentaje = this.toFixed(
        (this.datos_barra.operativo.cant * 100) / tiempo_servicio
      );

      this.datos_barra.disponible.porcentaje = this.toFixed(
        (this.datos_barra.disponible.cant * 100) / tiempo_servicio
      );

      this.datos_barra.indisponible.porcentaje = this.toFixed(
        (this.datos_barra.indisponible.cant * 100) / tiempo_servicio
      );

      this.datos_barra.movilizacion.porcentaje = this.toFixed(
        (this.datos_barra.movilizacion.cant * 100) / tiempo_servicio
      );

      // Se asignan los tiempos
      this.datos_barra.operativo.tiempo = {
        years: moment.duration(this.datos_barra.operativo.cant).years(),
        meses: moment.duration(this.datos_barra.operativo.cant).months(),
        dias: moment.duration(this.datos_barra.operativo.cant).days(),
        horas: moment.duration(this.datos_barra.operativo.cant).hours(),
        minutos: moment.duration(this.datos_barra.operativo.cant).minutes(),
      };

      this.datos_barra.disponible.tiempo = {
        years: moment.duration(this.datos_barra.disponible.cant).years(),
        meses: moment.duration(this.datos_barra.disponible.cant).months(),
        dias: moment.duration(this.datos_barra.disponible.cant).days(),
        horas: moment.duration(this.datos_barra.disponible.cant).hours(),
        minutos: moment.duration(this.datos_barra.disponible.cant).minutes(),
      };

      this.datos_barra.indisponible.tiempo = {
        years: moment.duration(this.datos_barra.indisponible.cant).years(),
        meses: moment.duration(this.datos_barra.indisponible.cant).months(),
        dias: moment.duration(this.datos_barra.indisponible.cant).days(),
        horas: moment.duration(this.datos_barra.indisponible.cant).hours(),
        minutos: moment.duration(this.datos_barra.indisponible.cant).minutes(),
      };

      this.datos_barra.movilizacion.tiempo = {
        years: moment.duration(this.datos_barra.movilizacion.cant).years(),
        meses: moment.duration(this.datos_barra.movilizacion.cant).months(),
        dias: moment.duration(this.datos_barra.movilizacion.cant).days(),
        horas: moment.duration(this.datos_barra.movilizacion.cant).hours(),
        minutos: moment.duration(this.datos_barra.movilizacion.cant).minutes(),
      };

      this.datos_barra2 = {
        operativo: {
          cant: 0,
          tiempo: {},
          porcentaje: 0,
        },
        disponible: {
          cant: 0,
          tiempo: {},
          porcentaje: 0,
        },
        indisponible: {
          cant: 0,
          tiempo: {},
          porcentaje: 0,
        },
        movilizacion: {
          cant: 0,
          tiempo: {},
          porcentaje: 0,
        },
      };

      this.csDetalles2.forEach((det) => {
        // Se calcula el tiempo del reporte
        const inicio_reporte = new Date(`${det.hora_ini}`);
        const fin_reporte = new Date(`${det.hora_fin}`);
        const tiempo_reporte = fin_reporte.getTime() - inicio_reporte.getTime();

        switch (det.tipo_tiempo) {
          case 1:
            this.datos_barra2.operativo.cant += tiempo_reporte;
            break;
          case 2:
            this.datos_barra2.disponible.cant += tiempo_reporte;
            break;
          case 3:
            this.datos_barra2.indisponible.cant += tiempo_reporte;
            break;
          case 4:
            this.datos_barra2.movilizacion.cant += tiempo_reporte;
            break;
          default:
            break;
        }
      });

      this.csDetalles2.forEach((det) => {
        const inicio_f = det.hora_ini.split(" ");
        const fin_f = det.hora_fin.split(" ");
        det.fecha_ini = inicio_f[0];
        det.hora_ini = inicio_f[1];
        det.fecha_fin = fin_f[0];
        det.hora_fin = fin_f[1];
        det.tiempo = this.getTiempo(
          `${det.fecha_ini} ${det.hora_ini}`,
          `${det.fecha_fin} ${det.hora_fin}`
        );
      });

      // Se calcula el tiempo del servicio
      let fechaIni2 = this.servicio.fecha_ini.split(" ");
      const inicio_servicio2 = new Date(`${fechaIni2[0]} ${"18:00"}`);
      const fin_servicio2 = new Date(`${this.servicio.fecha_fin}`);
      const tiempo_servicio2 =
        fin_servicio2.getTime() - inicio_servicio2.getTime();

      this.datos_barra2.operativo.porcentaje = this.toFixed(
        (this.datos_barra2.operativo.cant * 100) / tiempo_servicio2
      );
      this.datos_barra2.disponible.porcentaje = this.toFixed(
        (this.datos_barra2.disponible.cant * 100) / tiempo_servicio2
      );
      this.datos_barra2.indisponible.porcentaje = this.toFixed(
        (this.datos_barra2.indisponible.cant * 100) / tiempo_servicio2
      );
      this.datos_barra2.movilizacion.porcentaje = this.toFixed(
        (this.datos_barra2.movilizacion.cant * 100) / tiempo_servicio2
      );

      // Se asignan los tiempos
      this.datos_barra2.operativo.tiempo = {
        years: moment.duration(this.datos_barra2.operativo.cant).years(),
        meses: moment.duration(this.datos_barra2.operativo.cant).months(),
        dias: moment.duration(this.datos_barra2.operativo.cant).days(),
        horas: moment.duration(this.datos_barra2.operativo.cant).hours(),
        minutos: moment.duration(this.datos_barra2.operativo.cant).minutes(),
      };
      this.datos_barra2.disponible.tiempo = {
        years: moment.duration(this.datos_barra2.disponible.cant).years(),
        meses: moment.duration(this.datos_barra2.disponible.cant).months(),
        dias: moment.duration(this.datos_barra2.disponible.cant).days(),
        horas: moment.duration(this.datos_barra2.disponible.cant).hours(),
        minutos: moment.duration(this.datos_barra2.disponible.cant).minutes(),
      };
      this.datos_barra2.indisponible.tiempo = {
        years: moment.duration(this.datos_barra2.indisponible.cant).years(),
        meses: moment.duration(this.datos_barra2.indisponible.cant).months(),
        dias: moment.duration(this.datos_barra2.indisponible.cant).days(),
        horas: moment.duration(this.datos_barra2.indisponible.cant).hours(),
        minutos: moment.duration(this.datos_barra2.indisponible.cant).minutes(),
      };
      this.datos_barra2.movilizacion.tiempo = {
        years: moment.duration(this.datos_barra2.movilizacion.cant).years(),
        meses: moment.duration(this.datos_barra2.movilizacion.cant).months(),
        dias: moment.duration(this.datos_barra2.movilizacion.cant).days(),
        horas: moment.duration(this.datos_barra2.movilizacion.cant).hours(),
        minutos: moment.duration(this.datos_barra2.movilizacion.cant).minutes(),
      };
    },

    limpiarModal() {
      this.form = {};
      this.servicio = {};
      this.csDetalles = [];
      this.csDetallesExtras = [];
      this.csDetalles2 = [];
      this.csDetallesExtras2 = [];
    },

    toFixed(num) {
      return Number.parseFloat(num).toFixed(2);
    },
  },
};
</script>
